// eslint-disable-next-line no-restricted-imports
import { Avatar, Box, BoxProps, Button, Divider, Flex, HStack, Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { IWikifolioComplement, TUnderlying } from "src/api/client/underlying-wikifolio-complement/underlying-wikifolio-complement.api";
import { DescriptionDefinition, DescriptionList, DescriptionTerm } from "src/components/base";
import { WfLinkGhost, WfLinkUnstyled } from "src/components/base/wf-link";
import { useNumberFormatter } from "src/hooks/formatters/use-number-formatter";
import { useLinkBuilder } from "src/hooks/use-link-builder";
import { ComplementValueDisplay, VALUE_DISPLAY_VARIANT } from "./complement-value-display";

interface IWikifolioCard extends BoxProps {
    complement: IWikifolioComplement;
    underlying: TUnderlying;
}

export const MixEtfWithWikifolioCard = ({ underlying, complement, ...boxProps }: IWikifolioCard) => {
    const { wikifolio } = complement;
    const { trader } = wikifolio;

    const { buildTraderProfileLink, buildWikifolioLink } = useLinkBuilder();
    const { formatPerformance, formatVolatility } = useNumberFormatter();

    const { t } = useTranslation("mix-etf-with-wikifolio-module");

    const traderProfileLink = buildTraderProfileLink(trader.nickName);
    const WikifolioLink = buildWikifolioLink(wikifolio.symbol);

    return (
        <Box flex={1} boxShadow="base" rounded="8px" p={[2, 3]} {...boxProps}>
            <HStack spacing={1}>
                <Box flexShrink={0}>
                    <Avatar
                        bg="transparent"
                        variant="grayOutline"
                        borderColor="lilac.600"
                        borderWidth="2px"
                        src={underlying.imageUrl}
                        name={underlying.shortDescription}
                        loading="eager"
                    />

                    <WfLinkUnstyled href={traderProfileLink} marginLeft={-1}>
                        <Avatar
                            bg="white"
                            variant="grayOutline"
                            borderColor="green.600"
                            borderWidth="2px"
                            src={trader.traderImageUrl}
                            name={trader.displayName}
                            loading="eager"
                        />
                    </WfLinkUnstyled>
                </Box>
                <Flex flexDir="column" flexGrow={1} overflow="hidden">
                    <WfLinkGhost href={WikifolioLink} noOfLines={1} wordBreak="break-all">
                        {wikifolio.shortDescription}
                    </WfLinkGhost>

                    <WfLinkGhost href={traderProfileLink} fontWeight="normal" fontSize="sm" noOfLines={1} wordBreak="break-all">
                        {trader.displayName}
                    </WfLinkGhost>
                </Flex>
            </HStack>

            <Text as="h5" fontWeight="bold" pt={3}>
                {t("titles.performance")}
            </Text>
            <Stack as={DescriptionList} direction="column" justify="space-between" align="flex-start" spacing={0}>
                <Flex width="100%" py={1} justifyContent="space-between">
                    <Text as={DescriptionTerm} fontSize="md">
                        {t("labels.etf")}
                    </Text>
                    <Text as={DescriptionDefinition} fontSize="md" fontWeight="bold">
                        {formatPerformance(underlying.annualizedPerformance, 1)}
                    </Text>
                </Flex>
                <Divider borderColor="gray.100" />
                <Flex width="100%" py={1} justifyContent="space-between">
                    <Text as={DescriptionTerm}> {t("labels.etf-plus-wikifolio")} </Text>
                    <ComplementValueDisplay variant={VALUE_DISPLAY_VARIANT.annualizedPerformance} complement={complement} />
                </Flex>
                <Divider borderColor="gray.100" />
                <Flex width="100%" py={1} justifyContent="space-between">
                    <Text as={DescriptionTerm}> {t("labels.potential")} </Text>
                    <ComplementValueDisplay variant={VALUE_DISPLAY_VARIANT.annualizedPerformanceChange} complement={complement} />
                </Flex>
                <Divider borderColor="gray.100" />
            </Stack>

            <Text as="h5" fontWeight="bold" pt={3}>
                {t("titles.volatility")}
            </Text>
            <Stack as={DescriptionList} direction="column" justify="space-between" align="flex-start" spacing={0}>
                <Flex width="100%" py={1} justifyContent="space-between">
                    <Text as={DescriptionTerm} fontSize="md">
                        {t("labels.etf")}
                    </Text>
                    <Text as={DescriptionDefinition} fontSize="md" fontWeight="bold">
                        {formatVolatility(underlying.annualizedVolatility, 1)}
                    </Text>
                </Flex>
                <Divider borderColor="gray.100" />
                <Flex width="100%" py={1} justifyContent="space-between">
                    <Text as={DescriptionTerm}> {t("labels.etf-plus-wikifolio")} </Text>
                    <ComplementValueDisplay variant={VALUE_DISPLAY_VARIANT.annualizedVolatility} complement={complement} />
                </Flex>
                <Divider borderColor="gray.100" />
                <Flex width="100%" py={1} justifyContent="space-between">
                    <Text as={DescriptionTerm}> {t("labels.potential")} </Text>
                    <ComplementValueDisplay variant={VALUE_DISPLAY_VARIANT.annualizedVolatilityChange} complement={complement} />
                </Flex>
                <Divider borderColor="gray.100" />
            </Stack>

            <Button as={WfLinkUnstyled} href={WikifolioLink} w="full" colorScheme="green" size="sm" mt={3}>
                {t("buttons.invest-now")}
            </Button>
        </Box>
    );
};
