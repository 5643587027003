import { useRef } from "react";

interface IUseOnceProps {
    condition: boolean;
    callback: () => void;
}

export const useOnce = ({ condition, callback }: IUseOnceProps): void => {
    const didRun = useRef(false);

    if (!didRun.current && condition) {
        callback();
        didRun.current = true;
    }
};
