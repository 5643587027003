// eslint-disable-next-line no-restricted-imports
import { Box, BoxProps } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import { useOnce } from "src/hooks/use-once";
import { WikifolioEtfSlider } from "./components/slider/wikifolio-etf-slider";
import { DEFAULT_MIX_ETF_WIKIFOLIO_RANGE } from "./constants";
import { useEtfWithWikifolioRangeStore } from "./store/mix-etf-with-wikifolio-store";

interface IStep2Section extends BoxProps {
    sectionTitle: string;
    isLoading: boolean;
}

export const Step2Section = ({ onReset, sectionTitle, title, isLoading, ...boxProps }: IStep2Section) => {
    const store = useEtfWithWikifolioRangeStore();
    useOnce({ condition: store.range !== DEFAULT_MIX_ETF_WIKIFOLIO_RANGE, callback: () => store.setRange(DEFAULT_MIX_ETF_WIKIFOLIO_RANGE) });

    return (
        <Box as="section" {...boxProps}>
            <Text fontSize="lg" fontWeight="bold" as="h3" pb={2}>
                {sectionTitle}
            </Text>
            <WikifolioEtfSlider
                defaultValue={DEFAULT_MIX_ETF_WIKIFOLIO_RANGE}
                isDisabled={isLoading}
                onChange={value => store.setRange(value)}
                isPercentagesInverted
            />
        </Box>
    );
};
