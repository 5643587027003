import { proxy, useSnapshot } from "valtio";
import { DEFAULT_MIX_ETF_WIKIFOLIO_RANGE } from "../constants";

const mixEtfWithWikifolioStore = proxy({
    range: DEFAULT_MIX_ETF_WIKIFOLIO_RANGE,

    setRange(range: number) {
        mixEtfWithWikifolioStore.range = range;
    },
});

export function useEtfWithWikifolioRangeStore() {
    return useSnapshot(mixEtfWithWikifolioStore);
}
