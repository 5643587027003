import { Text, TextProps } from "@chakra-ui/react";
import { IWikifolioComplement } from "src/api/client/underlying-wikifolio-complement/underlying-wikifolio-complement.api";
import { DescriptionDefinition } from "src/components/base";
import { useEtfWithWikifolioRangeStore } from "src/components/modules/mix-etf-with-wikifolio-module/store/mix-etf-with-wikifolio-store";
import { useNumberFormatter } from "src/hooks/formatters/use-number-formatter";

export const VALUE_DISPLAY_VARIANT = {
    annualizedPerformance: "annualizedPerformance",
    annualizedPerformanceChange: "annualizedPerformanceChange",
    annualizedVolatility: "annualizedVolatility",
    annualizedVolatilityChange: "annualizedVolatilityChange",
} as const;

interface IComplementValueDisplay {
    complement: IWikifolioComplement;
    variant: (typeof VALUE_DISPLAY_VARIANT)[keyof typeof VALUE_DISPLAY_VARIANT];
}

export const ComplementValueDisplay = ({ complement, variant }: IComplementValueDisplay) => {
    const { range } = useEtfWithWikifolioRangeStore();
    const { formatPerformance, formatVolatility } = useNumberFormatter();

    const index = Math.floor(range / 10) - 1;
    const value = complement.portfolioScenarios[index][variant];

    const formattedValue = (() => {
        if (variant === VALUE_DISPLAY_VARIANT.annualizedVolatility) {
            return formatVolatility(value, 1);
        }
        return formatPerformance(value, 1);
    })();

    const getTextColor = (): TextProps["color"] => {
        if (variant === VALUE_DISPLAY_VARIANT.annualizedVolatilityChange) {
            return value < 0 ? "green.600" : "red.600";
        }
        if (variant === VALUE_DISPLAY_VARIANT.annualizedPerformanceChange) {
            return "green.600";
        }

        return undefined;
    };

    return (
        <Text as={DescriptionDefinition} fontWeight="bold" fontSize="md" color={getTextColor()}>
            {formattedValue}
        </Text>
    );
};
